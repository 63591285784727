import React, { useState, useEffect, useCallback } from "react";
import { Formik, Field, Form, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Tabs, Tab, Box, Typography } from "@mui/material";
import { toast } from "react-toastify";
import { PlusIcon, TrashIcon } from "@heroicons/react/20/solid";

import FileUpload from "../../../components/FileUpload/FileUpload";
import BrandService from "../../../services/admin/brand.services";
import UnitService from "../../../services/admin/unit.services";
import TaxService from "../../../services/admin/tax.services";
import DiscountService from "../../../services/admin/discount.services";
import CategoriesService from "../../../services/admin/categories.services";
import SubCategoriesService from "../../../services/admin/subCategories.services";
import ProductService from "../../../services/admin/product.services";
import { isEmpty } from "lodash";
import { Tooltip } from "@mui/material";
import MerchantService from "../../../services/admin/merchant.services";

// Initial values and schema
const initialValues = {
  id: 0,
  merchantId: "",
  organicType: "",
  vegetarianType: "",
  productName: "",
  categoryId: "",
  subCategoryId: "",
  brandId: "",
  sku: "",
  taxId: "",
  status: "",
  description: "",
  quantitySizes: [
    { id: 0, size: "", unitId: "", purchasePrice: "", price: "", stock: "", discountId: "" },
  ],
  productImages: [{ attachmentId: "", attachmentName: "" }],
};
const validationSchema = Yup.object().shape({
  merchantId: Yup.string().required("Merchant Name is required"),
  productName: Yup.string().required("Product Name is required"),
  categoryId: Yup.number().required("Category is required"),
  subCategoryId: Yup.number().required("Sub Category is required"),
  brandId: Yup.string().required("Brand is required"),
  sku: Yup.string().required("SKU is required"),
  organicType: Yup.string().required("Organic type is required"),
  vegetarianType: Yup.string().required("Vegetarian type is required"),
  taxId: Yup.string().required("Tax Type is required"),
  status: Yup.boolean().required("Status is required"),
  description: Yup.string().optional(),
  quantitySizes: Yup.array().of(
    Yup.object({
      id: Yup.string().optional(),
      size: Yup.string().required("Size is required"),
      quantitySizeId: Yup.string().optional(),
      unitId: Yup.string().required("Unit is required"),
      purchasePrice: Yup.number()
        .required("Purchase price is required")
        .positive("Purchase price must be positive"),
      price: Yup.number()
        .required("Price is required")
        .positive("Price must be positive"),
      stock: Yup.number()
        .required("Stock is required")
        .integer("Stock must be an integer")
        .min(1, "Stock must be at least 1"),
      discountId: Yup.string().required("Discount is required"),
    })
  ),
  productImages: Yup.array()
    .of(
      Yup.object({
        attachmentId: Yup.string().required("Attachment ID is required"),
        attachmentName: Yup.string().required("A image file is required"), // Validates the image name
      })
    )
    .min(1, "At least one product image is required"), // Ensures at least on
});

const ProductsForm = () => {
  const params = useParams();
  const id = params.id;
  const navigate = useNavigate();
  const [value, setValue] = useState(0); // Manage tab state
  const [data, setData] = useState(initialValues);
  const [brand, setBrand] = useState([]);
  const [unit, setUnit] = useState([]);
  const [tax, setTax] = useState([]);
  const [discount, setDiscount] = useState([]);
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [merchants, setMerchants] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState("");
  const [subCategories, setSubCategories] = useState([]);
  const BASE_URL = process.env.REACT_APP_API_URL;
  // eslint-disable-next-line no-unused-vars
  const [selectedSubCategories, setSelectedSubCategories] = useState("");

  const handleCategoriesChange = async (event) => {
    const selectedCategories = event.target.value;
    setSelectedCategories(selectedCategories);
    setSelectedSubCategories("");
    if (selectedCategories) {
      try {
        const response = await SubCategoriesService.findAllCategories(
          selectedCategories
        );
        setSubCategories(response.data.data);
      } catch (error) {
        console.error("Error fetching districts:", error);
      }
    } else {
      setSubCategories([]);
    }
  };
  const handleSubCategoriesChange = (event) => {
    const selectedSubCategories = event.target.value;
    setSelectedSubCategories(selectedSubCategories);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSubmit = async (values, { resetForm }) => {
    // Start loading to show loading indicator
    setLoading(true);
    const requestData = {
      quantitySizeId: values.quantitySizeId,
      merchantId: values.merchantId,
      productName: values.productName,
      categoryId: values.categoryId,
      subCategoryId: values.subCategoryId,
      brandId: values.brandId,
      sku: values.sku,
      taxId: values.taxId,
      status: values.status !== undefined ? values.status : true, // Handle status
      description: values.description, // Optional field
      organicType: values.organicType, // Optional field
      vegetarianType: values.vegetarianType, // Optional field
      quantitySizes: values.quantitySizes,
      productImages: values.productImages,
    };

    try {
      console.log("Product Data: ", requestData);
      const response = (!isEmpty(id)) && (id > 0)
        ? await ProductService.update(id, requestData) // Update logic
        : await ProductService.create(requestData); // Create logic

      if (response.data) {
        toast.success(id ? "Product updated successfully!" : "Product added successfully!");
        resetForm(); // Reset form after successful submission
        navigate("/admin/products");
      } else {
        toast.error(response.message || "Something went wrong.");
      }
    } catch (error) {
      toast.error(id ? "Failed to update product" : "Failed to create product");
    } finally {
      setLoading(false);
    }
  };


  const fetchMerchant = useCallback(async () => {
    setLoading(true);
    try {
      const response = await MerchantService.findAllMerchant();
      // console.log(response)
      const result = response.data.data.rows;
      // console.log(result)
      if (Array.isArray(result)) {
        setMerchants(result);
      } else {
        toast.error("Unexpected response format");
      }
    } catch (error) {
      console.error("Error fetching brand:", error);
      toast.error("Failed to fetch brand");
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchBrand = useCallback(async () => {
    setLoading(true);
    try {
      const response = await BrandService.findAllBrand();
      // console.log(response)
      const result = response.data.data.rows;
      // console.log(result)
      if (Array.isArray(result)) {
        setBrand(result);
      } else {
        toast.error("Unexpected response format");
      }
    } catch (error) {
      console.error("Error fetching brand:", error);
      toast.error("Failed to fetch brand");
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchCategories = useCallback(async () => {
    setLoading(true);
    try {
      const response = await CategoriesService.findAllCategories();
      // console.log(response)
      const result = response.data.data.rows;
      // console.log(result)
      if (Array.isArray(result)) {
        setCategories(result);
      } else {
        toast.error("Unexpected response format");
      }
    } catch (error) {
      console.error("Error fetching category:", error);
      toast.error("Failed to fetch category");
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchUnit = useCallback(async () => {
    setLoading(true);
    try {
      const response = await UnitService.findAllUnit();
      // console.log(response)
      const result = response.data.data.rows;
      // console.log(result)
      if (Array.isArray(result)) {
        setUnit(result);
      } else {
        toast.error("Unexpected response format");
      }
    } catch (error) {
      console.error("Error fetching unit:", error);
      toast.error("Failed to fetch unit");
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchTax = useCallback(async () => {
    setLoading(true);
    try {
      const response = await TaxService.findAllTax();
      // console.log(response)
      const result = response.data.data.rows;
      // console.log(result)
      if (Array.isArray(result)) {
        setTax(result);
      } else {
        toast.error("Unexpected response format");
      }
    } catch (error) {
      console.error("Error fetching tax:", error);
      toast.error("Failed to fetch tax");
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchDiscount = useCallback(async () => {
    setLoading(true);
    try {
      const response = await DiscountService.findAllDiscount();
      // console.log(response)
      const result = response.data.data.rows;
      // console.log(result)
      if (Array.isArray(result)) {
        setDiscount(result);
      } else {
        toast.error("Unexpected response format");
      }
    } catch (error) {
      console.error("Error fetching discount:", error);
      toast.error("Failed to fetch discount");
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchProductData = useCallback(async () => {
    try {
      if (!isEmpty(id) && id > 0) {
        const response = await ProductService.findOne(id);
        console.log(response, "fetchProductData");
        if (response.status) {
          setData(response.data.data);
        } else {
          toast.error("Failed to load product data.");
        }
      }
    } catch (error) {
      console.error("Error fetching product data:", error);
      toast.error("Failed to load product data.");
    }
  }, [id]);

  useEffect(() => {
    fetchBrand();
    fetchUnit();
    fetchTax();
    fetchMerchant();
    fetchDiscount();
    fetchCategories();
  }, [
    fetchBrand,
    fetchUnit,
    fetchTax,
    fetchDiscount,
    fetchCategories,
    fetchMerchant,
  ]);

  useEffect(() => {
    fetchProductData();
  }, [fetchProductData]);

  const previewStyle = {
    display: "flex",
    alignItems: "center",
    marginTop: "10px",
    gap: "10px",
    transition: "border-color 0.2s ease",
    borderRadius: "9px",
  };
  return (
    <div className="content">
      <div className="page-header">
        <div className="page-title">
          <h4>{id ? "Edit Product" : "Add Product"}</h4>
          <h6>{id ? "Update the product details" : "Create a new product"}</h6>
        </div>
      </div>

      <div className="card">
        <div className="card-body">
          <Formik
            initialValues={data}
            validationSchema={validationSchema}
            enableReinitialize={true}
            onSubmit={handleSubmit}
          >
            {({ values, setFieldValue }) => (
              <Form>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="Product Form Tabs"
                  sx={{
                    overflowX: 'auto',
                    whiteSpace: 'nowrap',
                    '& .MuiTabs-flexContainer': {
                      flexWrap: 'nowrap',
                    },
                  }}
                >
                  <Tab label="General Info" />
                  <Tab label="Pricing & Stock" />
                  <Tab label="Images" />
                  <Tab label="Description & Status" />
                </Tabs>

                <Box sx={{ padding: 3 }}>
                  {/* General Info Tab */}
                  <Typography
                    component="div"
                    sx={{
                      border: "1px solid #ccc",
                      borderRadius: "8px",
                      padding: 3,
                    }}
                    role="tabpanel"
                    hidden={value !== 0}
                  >
                    <div className="row">
                      <div className="col-lg-4 col-sm-6 col-12">
                        <div className="form-group">
                          <label>
                            Merchant Branch Code{" "}
                            <span className="manitory">*</span>{" "}
                          </label>
                          <Field
                            as="select"
                            name="merchantId"
                            className="form-control"
                            onChange={(e) => {
                              setFieldValue("merchantId", e.target.value);
                            }}
                          >
                            <option value="">Choose Merchant</option>
                            {merchants.map((merchant) => (
                              <option key={merchant.id} value={merchant.id}>
                                {merchant.branchCode}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage
                            name="categoryId"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-sm-6 col-12">
                        <div className="form-group">
                          <label>
                            Product Name <span className="manitory">*</span>{" "}
                          </label>
                          <Field
                            type="text"
                            name="productName"
                            className="form-control"
                          />
                          <ErrorMessage
                            name="productName"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-sm-6 col-12">
                        <div className="form-group">
                          <label>
                            Category <span className="manitory">*</span>{" "}
                          </label>
                          <Field
                            as="select"
                            name="categoryId"
                            className="form-control"
                            onChange={(e) => {
                              handleCategoriesChange(e);
                              setFieldValue("categoryId", e.target.value);
                            }}
                          >
                            <option value="">Choose Category</option>
                            {categories.map((category) => (
                              <option key={category.id} value={category.id}>
                                {category.categoriesName}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage
                            name="categoryId"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-sm-6 col-12">
                        <div className="form-group">
                          <label>
                            Sub Category <span className="manitory">*</span>
                          </label>
                          <Field
                            disabled={!selectedCategories}
                            as="select"
                            name="subCategoriesId"
                            className="form-control"
                            onChange={(e) => {
                              handleSubCategoriesChange(e);
                              setFieldValue("subCategoryId", e.target.value);
                            }}
                          >
                            <option value="">Choose Sub Category</option>

                            {subCategories.map((category) => (
                              <option key={category.id} value={category.id}>
                                {category.subCategoriesName}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage
                            name="subCategoriesId"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-sm-6 col-12">
                        <div className="form-group">
                          <label>
                            Brand <span className="manitory">*</span>
                          </label>
                          <Field
                            as="select"
                            name="brandId"
                            className="form-control"
                          >
                            <option value="">Choose Brand</option>
                            {brand.map((brands) => (
                              <option key={brands.id} value={brands.id}>
                                {brands.brandName}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage
                            name="brandId"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                      </div>

                      <div className="col-lg-4 col-sm-6 col-12">
                        <div className="form-group">
                          <label>Tax</label>
                          <Field
                            as="select"
                            name="taxId"
                            className="form-control"
                          >
                            <option value="">Choose Tax</option>
                            {tax.map((t) => (
                              <option key={t.id} value={t.id}>
                                {t.taxName} {t.taxPars}%
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage
                            name="taxId"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                      </div>

                      <div className="col-lg-4 col-sm-6 col-12">
                        <div className="form-group">
                          <label>
                            SKU <span className="manitory">*</span>{" "}
                          </label>
                          <Field
                            type="text"
                            name="sku"
                            className="form-control"
                          />
                          <ErrorMessage
                            name="sku"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-sm-6 col-12">
                        <div className="form-group">
                          <label>Product Type (Veg / Non-Veg) <span className="manitory">*</span></label>

                          <Field as="select" name="vegetarianType" className="form-control">
                            <option value="">Choose Product Type</option>
                            <option value="vegetarian">Vegetarian</option>
                            <option value="non-vegetarian">Non-Vegetarian</option>
                          </Field>
                          <ErrorMessage name="vegetarianType" component="div" className="text-danger" />
                        </div>
                      </div>
                      <div className="col-lg-4 col-sm-6 col-12">
                        <div className="form-group">
                          <label>Product Type (Organic / Non-Organic) <span className="manitory">*</span></label>
                          <Field as="select" name="organicType" className="form-control">
                            <option value="">Choose Product Type</option>
                            <option value="organic">Organic</option>
                            <option value="non-organic">Non-Organic</option>
                          </Field>
                          <ErrorMessage name="organicType" component="div" className="text-danger" />
                        </div>
                      </div>

                    </div>
                  </Typography>

                  {/* Pricing & Stock Tab */}
                  <Typography
                    component="div"
                    role="tabpanel"
                    sx={{
                      border: "1px solid #ccc",
                      borderRadius: "8px",
                      padding: 3,
                    }}
                    hidden={value !== 1}
                  >
                    <div className="row">
                      <FieldArray name="quantitySizes">
                        {({ push, remove }) => (
                          <>
                            {/* Dynamically render product fields */}
                            {values.quantitySizes.map((product, index) => (
                              <div key={index}
                                className="flex mt-2 space-x-4 bg-gray-50"
                                style={{
                                  border: "1px solid #ddd",
                                  borderRadius: "8px",
                                  padding: "10px",
                                }}>
                                {/* Size (Weight) */}
                                <div className="col-lg-2 col-sm-6 col-12">
                                  <div className="form-group">
                                    <label>
                                      Size (Weight)
                                      <span className="manitory">*</span>
                                    </label>
                                    <Field
                                      type="hidden"
                                      name={`quantitySizes[${index}].id`}
                                      className="form-control"
                                    />
                                    <Field
                                      type="text"
                                      name={`quantitySizes[${index}].size`}
                                      className="form-control"
                                    />
                                    <ErrorMessage
                                      name={`quantitySizes[${index}].size`}
                                      component="div"
                                      className="text-danger"
                                    />
                                  </div>
                                </div>

                                {/* Unit */}
                                <div className="col-lg-2 col-sm-6 col-12">
                                  <div className="form-group">
                                    <label>
                                      Unit <span className="manitory">*</span>
                                    </label>
                                    <Field
                                      as="select"
                                      name={`quantitySizes[${index}].unitId`}
                                      className="form-control"
                                    >
                                      <option value="">Choose Unit</option>
                                      {unit.map((units) => (
                                        <option key={units.id} value={units.id}>
                                          {units.unitType}
                                        </option>
                                      ))}
                                    </Field>
                                    <ErrorMessage
                                      name={`quantitySizes[${index}].unitId`}
                                      component="div"
                                      className="text-danger"
                                    />
                                  </div>
                                </div>

                                {/*Buy Price */}
                                <div className="col-lg-1 col-sm-6 col-12">
                                  <div className="form-group">
                                    <label>
                                      Buy Price<span className="manitory">*</span>
                                    </label>
                                    <Field
                                      type="text"
                                      name={`quantitySizes[${index}].purchasePrice`}
                                      className="form-control"
                                    />
                                    <ErrorMessage
                                      name={`quantitySizes[${index}].purchasePrice`}
                                      component="div"
                                      className="text-danger"
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-1 col-sm-6 col-12">
                                  <div className="form-group">
                                    <label>
                                      Sell Price <span className="manitory">*</span>
                                    </label>
                                    <Field
                                      type="text"
                                      name={`quantitySizes[${index}].price`}
                                      className="form-control"
                                    />
                                    <ErrorMessage
                                      name={`quantitySizes[${index}].price`}
                                      component="div"
                                      className="text-danger"
                                    />
                                  </div>
                                </div>

                                {/* Stock Quantity */}
                                <div className="col-lg-2 col-sm-6 col-12">
                                  <div className="form-group">
                                    <label>
                                      Stock Quantity
                                      <span className="manitory">*</span>
                                    </label>
                                    <Field
                                      type="text"
                                      name={`quantitySizes[${index}].stock`}
                                      className="form-control"
                                    />
                                    <ErrorMessage
                                      name={`quantitySizes[${index}].stock`}
                                      component="div"
                                      className="text-danger"
                                    />
                                  </div>
                                </div>

                                {/* Discount Percentage */}
                                <div className="col-lg-2 col-sm-6 col-12">
                                  <div className="form-group">
                                    <label>
                                      Discount (%)
                                      <span className="manitory">*</span>
                                    </label>
                                    <Field
                                      as="select"
                                      name={`quantitySizes[${index}].discountId`}
                                      className="form-control"
                                    >
                                      <option value="">Choose Discount</option>
                                      {discount.map((dis, index) => (
                                        <option key={index} value={dis.id}>
                                          {dis.percentage}%
                                        </option>
                                      ))}
                                    </Field>
                                    <ErrorMessage
                                      name={`quantitySizes[${index}].discountId`}
                                      component="div"
                                      className="text-danger"
                                    />
                                  </div>
                                </div>

                                {/* Remove Product Button */}
                                <div className="col-lg-1 col-sm-6 col-12">
                                  <div className="form-group mt-4 p-2 d-flex justify-content-center align-items-center">
                                    <Tooltip title="Delete Price & Stock ">
                                      <Link
                                        className="me-3 confirm-text"
                                        onClick={() => remove(index)} // Ensure remove(index) is being used correctly for your field array or list
                                      >
                                        <TrashIcon className="h-5 w-5 text-red-600 cursor-pointer" />
                                      </Link>
                                    </Tooltip>
                                  </div>
                                </div>
                              </div>
                            ))}

                            {/* Add New Product Button */}
                            <div className="col-lg-6 col-sm-6 col-12 mt-2">
                              <Tooltip title="Add New Price & Stock ">
                                <button
                                  className="btn btn-submit me-2 flex items-center gap-1 px-2 py-1 text-sm" // added classes for slimming
                                  type="button" // use type="button" instead of type="submit" to avoid triggering form submit when clicked
                                  onClick={() =>
                                    push({
                                      size: "",
                                      unitId: "",
                                      purchasePrice: "",
                                      price: "",
                                      stock: "",
                                      discountId: "",
                                    })
                                  }
                                >
                                  <PlusIcon className="h-4 w-4" />{" "}
                                  {/* Adjust icon size for slimness */}
                                  Add New Product
                                </button>
                              </Tooltip>
                            </div>
                          </>
                        )}
                      </FieldArray>
                    </div>
                  </Typography>

                  {/*  Images Tab */}
                  <Typography
                    component="div"
                    sx={{
                      border: "1px solid #ccc",
                      borderRadius: "8px",
                      padding: 3,
                      boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)", // Optional: Add box shadow for extra styling
                    }}
                    role="tabpanel"
                    hidden={value !== 2}
                  >
                    <div className="row">
                      <FieldArray name="productImages">
                        {({ push, remove }) => (
                          <>
                            {/* Render existing product images */}
                            {values.productImages.map((image, index) => (
                              <div
                                key={index}
                                className="flex mt-2 space-x-4 bg-gray-50"
                                style={{
                                  border: "1px solid #ddd",
                                  borderRadius: "8px",
                                  padding: "10px",
                                }}
                              >
                                {/* Image Upload Section */}
                                <div className="col-lg-6 col-sm-6 col-12">
                                  <div className="form-group">
                                    <label>
                                      Shop Image:{" "}
                                      <span className="manitory">*</span>{" "}
                                    </label>

                                    <FileUpload
                                      setUploadRequest={[
                                        {
                                          attachmentId: image.attachmentId, // Specific to each image
                                          attachmentName: image.attachmentName, // Specific to each image
                                        },
                                      ]}
                                      setUploadResponse={(response) => {
                                        setFieldValue(
                                          `productImages[${index}].attachmentId`,
                                          response.attachmentId
                                        );
                                        setFieldValue(
                                          `productImages[${index}].attachmentName`,
                                          response.attachmentName
                                        );
                                      }}
                                    />
                                    <ErrorMessage
                                      name={`productImages[${index}].attachmentName`}
                                      component="div"
                                      style={{ color: "red" }}
                                    />
                                  </div>
                                </div>

                                {/* Image Preview Section */}
                                <div className="col-lg-4 col-sm-12 col-12">
                                  {image.attachmentName &&
                                    !isEmpty(image.attachmentName) ? (
                                    <div className="form-group">
                                      <label>Old Image:</label>
                                      <div style={previewStyle}>
                                        <img
                                          crossOrigin="anonymous"
                                          alt="Uploaded Preview"
                                          src={`${BASE_URL}${image.attachmentName}`}
                                          style={{
                                            maxWidth: "100%",
                                            maxHeight: "100px",
                                          }}
                                        />
                                      </div>
                                    </div>
                                  ) : null}
                                </div>

                                {/* Remove Product Button Section */}
                                <div className="col-lg-2 col-sm-6 col-12">
                                  <div className="form-group mt-4 p-2 d-flex justify-content-center align-items-center">
                                    <Tooltip title="Delete Product Image">
                                      <Link
                                        className="me-3 confirm-text"
                                        onClick={() => remove(index)} // Ensure remove(index) is being used correctly for your field array or list
                                      >
                                        <TrashIcon className="h-6 w-6 text-red-600 cursor-pointer" />
                                      </Link>
                                    </Tooltip>
                                  </div>
                                </div>
                              </div>
                            ))}

                            {/* Add New Product Button Section */}
                            <div className="col-lg-6 col-sm-6 col-12 mt-2">
                              <Tooltip title="Add New Product Image">
                                <button
                                  className="btn btn-submit me-2 flex items-center gap-1 px-2 py-1 text-sm"
                                  type="button" // Use type="button" instead of type="submit" to avoid triggering form submit when clicked
                                  onClick={() => {
                                    // Check if the number of images is less than 5 before adding more
                                    if (values.productImages.length < 5) {
                                      push({
                                        attachmentId: "",
                                        attachmentName: "",
                                      });
                                    } else {
                                      alert(
                                        "You can only upload up to 5 images."
                                      );
                                    }
                                  }}
                                  disabled={values.productImages.length >= 5} // Disable button if the limit is reached
                                >
                                  <PlusIcon className="h-4 w-4" />
                                  Add New Product Image
                                </button>
                              </Tooltip>
                            </div>
                          </>
                        )}
                      </FieldArray>
                    </div>
                  </Typography>

                  {/* Status & Description Tab */}
                  <Typography
                    component="div"
                    sx={{
                      border: "1px solid #ccc",
                      borderRadius: "8px",
                      padding: 3,
                    }}
                    role="tabpanel"
                    hidden={value !== 3}
                  >
                    <div className="row">
                      <div className="col-lg-8 col-sm-12 col-12">
                        <div className="form-group">
                          <label>Description</label>
                          <Field
                            as="textarea"
                            name="description"
                            className="form-control"
                            placeholder="(Optional)"
                          />
                          <ErrorMessage
                            name="description"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-sm-6 col-12">
                        <div className="form-group">
                          <label>
                            Status <span className="manitory">*</span>{" "}
                          </label>
                          <Field
                            as="select"
                            name="status"
                            className="form-control"
                          >
                            <option value="">Choose Status</option>
                            <option value="true">Active</option>
                            <option value="false">Inactive</option>
                          </Field>
                          <ErrorMessage
                            name="status"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                      </div>
                    </div>
                  </Typography>

                  <div className="col-lg-12 mt-3">
                    <button
                      className="btn btn-submit me-2"
                      type="submit"
                      disabled={loading}
                    >
                      {loading
                        ? "Saving..."
                        : id
                          ? "Update Product"
                          : "Add Product"}
                    </button>
                    <Link
                      to="/admin/products"
                      className="btn btn-cancel"
                      type="reset"
                    >
                      Cancel
                    </Link>
                  </div>
                </Box>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default ProductsForm;
